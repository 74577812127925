'use client'
import { cn } from '@chaseweb/utils/cn'
import { useSplitStore } from '@chaseweb/utils/split'
import { createContext, type ReactNode } from 'react'

export const featureFlaggerContext = createContext<Record<string, unknown>>({})

export const FeatureFlagger = ({
  children,
  featureFlag,
}: {
  children: ReactNode
  featureFlag?: {
    key: string
    treatment: string
    isControl: boolean
  } | null
}): ReactNode => {
  const client = useSplitStore((state) => state.clients.user)

  if (featureFlag) {
    const splitFlag = client?.getTreatmentWithConfig(featureFlag.key)

    if (splitFlag) {
      const shouldShowComponent =
        splitFlag.treatment === featureFlag.treatment || // show if the feature flag treatment matches for this feature
        (splitFlag.treatment === 'control' && featureFlag.isControl) // show if split is in CONTROL mode and the feature is marked as control

      const component = (
        <div
          className={cn({
            'tw-hidden': !shouldShowComponent,
            'ui-no-js-display-block': featureFlag?.isControl, // show when JS is disabled if the feature is marked as control
          })}
        >
          {children}
        </div>
      )

      if (splitFlag.config) {
        return (
          <featureFlaggerContext.Provider value={JSON.parse(splitFlag.config)}>
            {component}
          </featureFlaggerContext.Provider>
        )
      }
      return component
    }
  }
  return children
}
